import get from 'lodash/get'
import SvgIcon from '~/components/common/SvgIcon'
import styles from './DataTable.module.scss'
import clsx from 'clsx'
import LineLoader from '~/components/utils/LineLoader'

export interface IHeader<T = any> {
  name: string
  label?: string | JSX.Element
  align?: 'start' | 'end' | 'center'
  verticalAlign?: 'top' | 'bottom' | 'middle'
  sortable?: boolean
  className?: string
  component?: ({ row, value, rowIndex }: { row: T; value: any; rowIndex: number }) => JSX.Element
  props?: Record<string, any>
  style?: any
  footerComponent?: ({ rows }: { rows: T[] }) => JSX.Element
}

type Props<Row extends Record<string, any>> = {
  initialLoading?: boolean
  loading?: boolean
  headers: IHeader[]
  rows: Row[]
  total?: number
  perPage?: number
  page?: number
  sortable?: boolean
  sortKey?: string
  selected?: any[]
  selectedObject?: boolean
  selectedKey?: string
  layout?: string
  style?: any
  toggleOnClick?: boolean
  minWidth?: number
  onSelect?: (row: Row, event: MouseEvent) => void
  onSelected?: (value: any[]) => void
  sortBy?: string
  onSortBy?: (value: string) => void
  sortDesc?: boolean
  onSortDesc?: (value: boolean) => void
  className?: string
  showFooter?: boolean
  footer?: JSX.Element
}

export default function DataTable<Row extends Record<string, any>>({
  selectedKey = 'id',
  showFooter = false,
  ...props
}: Props<Row>) {
  const rows = props.initialLoading ? [...Array(5)] : props.rows
  const isSelectable = props.selected !== undefined
  const isClickable = props.onSelect !== undefined
  const isAnySelected = !!props.selected?.length
  const countOfColumns = props.headers.length + (isSelectable ? 1 : 0)

  const getClassNames = () => {
    const list = [styles.table]

    props.layout && list.push(props.layout)

    return list
  }

  const getSelectedIndex = (row: Row) => {
    if (!row) {
      return -1
    }

    if (!props.selected) {
      return -1
    }

    if (props.selectedObject) {
      return props.selected.findIndex((item) => item[selectedKey] === row[selectedKey])
    }

    return props.selected.indexOf(row[selectedKey])
  }

  const getIsSelected = (row: Row) => {
    return getSelectedIndex(row) !== -1
  }

  const getSelectedValue = (row: Row) => {
    return props.selectedObject ? row : row[selectedKey]
  }

  const getHeaderClass = (header: IHeader) => {
    const list = ['th']

    header.align && list.push('align-' + header.align)
    header.className && list.push(header.className)

    return list
  }

  const getRowClass = (row: Row) => {
    const list = ['tr']

    isSelectable && list.push('is-selectable')
    isClickable && row && !row.clickDisabled && list.push('is-clickable')
    getIsSelected(row) && list.push('is-selected')

    return list
  }

  const getColumnClass = (header: IHeader) => {
    const list = ['td']

    header.align && list.push('align-' + header.align)
    header.className && list.push(header.className)
    header.verticalAlign && list.push('vertical-align-' + header.verticalAlign)

    return list
  }

  const getValue = (row: Row, header: IHeader) => {
    if (!header.name) {
      return null
    }

    return get(row, header.name)
  }

  const toggleSelected = (row?: Row) => {
    if (!props.selected) {
      return
    }

    let selected = props.selected.slice(0)

    if (!row) {
      if (isAnySelected) {
        selected = []
      } else {
        selected = rows.map((row) => getSelectedValue(row))
      }
    } else {
      const index = getSelectedIndex(row)

      if (index > -1) {
        selected.splice(index, 1)
      } else {
        selected.push(getSelectedValue(row))
      }
    }

    props.onSelected && props.onSelected(selected)
  }

  const onSelect = (row: Row, e: MouseEvent) => {
    const target = e.target as HTMLElement

    if (
      target.tagName === 'BUTTON' ||
      target.closest('button') ||
      target.tagName === 'INPUT' ||
      !row ||
      row.clickDisabled
    ) {
      return
    }
    if (props.toggleOnClick) {
      toggleSelected(row)
    }

    if (props.onSelect) {
      props.onSelect(row, e)
    }

    // emit('select', row, e)
  }

  const sort = (name: string) => {
    const isEqual = props.sortBy === name

    if (isEqual) {
      props.onSortDesc && props.onSortDesc(!props.sortDesc)
    } else {
      props.onSortBy && props.onSortBy(name)
      props.onSortDesc && props.onSortDesc(true)
    }
  }

  const isAllSelected = isAnySelected && !rows.some((row) => !getIsSelected(row))

  return (
    <div className={clsx(styles['data-table'], props.className, 'position-relative')}>
      {props.loading && <LineLoader absolute />}
      <div className={styles['table-holder']}>
        <table
          className={clsx(getClassNames())}
          style={{ minWidth: props.minWidth ? props.minWidth + 'px' : '' }}
        >
          <thead className="head">
            <tr>
              {isSelectable && (
                <th className="th selectable skeleton-hide">
                  <button
                    type="button"
                    className={clsx('checkbox', { 'is-toggled': isAnySelected })}
                    onClick={() => toggleSelected()}
                  >
                    {isAllSelected ? (
                      <SvgIcon icon="check" height={16} />
                    ) : (
                      <div className="line"></div>
                    )}
                  </button>
                </th>
              )}

              {props.headers.map((item, i) => (
                <th key={i} className={clsx(getHeaderClass(item))} style={item.style || {}}>
                  <div
                    className={clsx('flex items-center', {
                      ['justify-' + item.align]: !!item.align
                    })}
                  >
                    {item.sortable ? (
                      <>
                        <button
                          type="button"
                          className={clsx('sort', 'flex', 'items-center', {
                            'is-active': props.sortBy === item.name,
                            'is-reverse': item.align === 'end'
                          })}
                          onClick={() => sort(item.name)}
                        >
                          <span
                            className={clsx(
                              'skeleton-text',
                              item.align === 'end' ? 'align-right' : ''
                            )}
                          >
                            {item.label}
                          </span>
                          <SvgIcon
                            icon="chevron-down"
                            className={clsx('skeleton-hide', 'icon', { rotate: !props.sortDesc })}
                          />
                        </button>
                      </>
                    ) : (
                      <span
                        className={clsx('skeleton-text', item.align === 'end' ? 'align-right' : '')}
                      >
                        {item.label}
                      </span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="body">
            {rows.map((row, i) => (
              <tr
                key={i}
                data-id={row?.id}
                className={clsx(getRowClass(row))}
                tabIndex={1}
                onClick={(e) => onSelect(row, e as any)}
              >
                {isSelectable && (
                  <td className="td selectable skeleton-hide">
                    <button
                      type="button"
                      className={clsx('checkbox', { 'is-toggled': getIsSelected(row) })}
                      onClick={() => toggleSelected(row)}
                    >
                      <SvgIcon icon="check" height={16} />
                    </button>
                  </td>
                )}
                {props.headers.map((column, j) => (
                  <td key={j} className={clsx(getColumnClass(column))}>
                    <div
                      className={clsx(
                        'flex items-center',
                        column.align ? 'justify-' + column.align : ''
                      )}
                    >
                      {row ? (
                        column.component ? (
                          <column.component
                            row={row}
                            value={getValue(row, column)}
                            rowIndex={i}
                            {...(column.props || {})}
                          />
                        ) : (
                          <span
                            className={clsx(
                              'skeleton-text',
                              column.align === 'end' ? 'align-right' : ''
                            )}
                          >
                            {getValue(row, column)}
                          </span>
                        )
                      ) : (
                        <span className="skeleton-value" />
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            ))}

            {showFooter && rows.length > 0 && (
              <tr className={clsx(styles.bold, styles.noBorder)}>
                <td className="td align-start">Total</td>
                <td className="td"></td>
                {props.headers.slice(2).map((header, index) => (
                  <td key={index} className="td align-start">
                    <div
                      className={clsx(
                        'flex items-center',
                        header.align ? 'justify-' + header.align : ''
                      )}
                    >
                      {header.footerComponent ? <header.footerComponent rows={rows} /> : '-'}
                    </div>
                  </td>
                ))}
              </tr>
            )}

            {!rows.length && (
              <tr className="tr">
                <td colSpan={countOfColumns} className="td align-center">
                  <div className="empty-state">
                    <slot name="empty"> No results found </slot>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
